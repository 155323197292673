<template>
  <div class="row g-4 gy-md-gs" v-loading="loading">

<!--    <div class="col-xl-6 col-lg-6 col-sm-6" v-for="product in SectionData.productData.products" :key="product.id">-->
<!--      <Products :product="product"></Products>-->
<!--    </div>-->
    <div class="col-xl-6 col-lg-6 col-sm-12" v-for="(product, index) in products" :key="product.id">
        <ProductsItem :product="product" :index="index" :bitCoin="this.bitCoin" :isRelated="true"></ProductsItem>
    </div>
    <!-- pagination -->
    <div class="text-center mt-4 mt-md-5" v-if="showPage">
      <Pagination :records="totalCount" v-model="planForm.pageNum" :per-page="planForm.pageSize"></Pagination>
    </div>

  </div>
</template>

<script>
import SectionData from '@/store/store.js'
import Products from '@/components/section/Products'
import ProductsItem from "@/components/section/ProductsItem";
import planApi from "@/apis/planApi";
import Pagination from "v-pagination-3";
import date from "@/date";
import indexApi from "@/apis/indexApi";

export default {
  name: 'ProductsSpecialContainer',
  components: {
    ProductsItem,Products, Pagination
  },
  props: {
    showPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
      return {
        bitCoin: '1',
        SectionData,
        products: [],
        loading: true,
        planForm: {
          limitTime: '',
          name: '',
          pageNum: 1,
          pageSize: 8
        },
        totalCount: 0,
        totalPages: 1

      }
  },
  watch: {
    'planForm.pageNum': function () {
      this.getTable()
    }
  },
  computed: {
    // dataList() {
    //   return this.data.filter(p => p.status === 1) // status 1 appear    2 disappear
    // },
  },
  created() {
    let source = sessionStorage.getItem('source') || ''
    if(source === '' || source === null ){
      const currentURL = window.location.href;
      source = new URL(currentURL).origin;
    }
    indexApi.GetBitcoin(source, res => {
          // alert(res.data)
          this.bitCoin = res.data
        }
    )
  },
  mounted() {
    //获取项目列表
    // this.loading = true;
    this.getTable()
  },
  methods:{
    //获取项目列表
    getTable() {
      this.loading = true;
      planApi.vipList(this.planForm, res => {
        if(res.code === 403 || res.code === 400){
          window.location.href = "/page-error"
        }else{
          this.loading = false;
          this.products = res.data?.result || [];
          this.totalCount = res.data?.totalCount || 0;
          this.totalPages = res.data?.totalPages || 1;
        }
      })

      // planApi.mine(this.transForm, res => {
      //   this.loading = false;
      //   console.log(res)
      //   this.records = res.data?.result || [];
      //   this.totalCount = res.data?.totalCount || 0;
      //   this.totalPages = res.data?.totalPages || 1;
      //   //获取到的数据小于等于全数据时
      //   // if (this.trans.result.length <= this.transForm.pageSize) this.transLoad = -1
      // })
    },
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    },
    search() {
      this.planForm.pageNum = 1;
      this.totalCount = 0;
      this.totalPages = 0;
      this.products = [];
      this.getTable()
    },
  }

};
</script>

<style scoped lang="scss">

</style>
