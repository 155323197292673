<template>
  <metainfo></metainfo>
  <div class="buy-plans">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>
    <!-- product  -->
    <section class="section-space trending-section" style="background: #F7F9FB;">
      <div class="container">
        <!-- section heading -->
        <SectionHeading classname="text-center" text="SPECIAL CONTRACTS"
                        content=""
                        isMargin="mb-3"></SectionHeading>
        <!-- product -->
        <ProductsSpecialContainer :showPage="true"></ProductsSpecialContainer>
        <!--                <div class="text-center mt-4 mt-md-5">-->
        <!--                    <ButtonLink :text="SectionData.productData.btnText" :link="SectionData.productData.btnLink" classname="btn-link btn-link-s1"></ButtonLink>-->
        <!--                </div>-->
      </div><!-- .container -->
    </section><!-- trending-section -->


    <!-- Footer  -->
    <Footer classname="bg-black on-dark"></Footer>
  </div>
</template>

<script>
import {useMeta} from "vue-meta"
import ProductsSpecialContainer from "@/components/section/ProductsSpecialContainer.vue";

export default {
  name: "SpecialPlans",
  components: {ProductsSpecialContainer},
  setup() {
    useMeta({
      title: 'Cryptocurrency Cloud Mining Services | Cloud Mining | SOHASH',
      description: 'Explore top-tier cryptocurrency cloud mining services at SOHASH. Maximize your digital assets with our secure and efficient mining solutions. Contact us today!',
      link: [
        { rel: 'canonical', href: 'https://sohash.com/special-contracts' }
      ],
      htmlAttrs: {lang: 'en', amp: true}
    })
  }
}
</script>

<style scoped>

</style>
